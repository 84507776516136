import { TENANT } from "../../constant";

export function isAthena(tenantId) {
  return tenantId === TENANT.ADVENTHEALTH ||
    tenantId === TENANT.DEVOTED;
}

export function isEpic(tenantId) {
  return tenantId === TENANT.OSU;
}

export function isOsu(tenantId) {
  return tenantId === TENANT.OSU;
}

export function isOptum(tenantId) {
  return tenantId === TENANT.OPTUM;
}